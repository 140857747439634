import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div id="app">
      <main>
        <img src={logo} alt="Logo" className="logo"/>
      </main>
      <footer>
        <ul>
          <li>
            <a href="tel:+31 6 45 77 05 69">+31 6 45 77 05 69</a>
          </li>
          <li>
            <a target='_blank' rel='noreferrer' href={`https://wa.me/31645770569?text=${encodeURIComponent('Bart, fijne vent, kun je me hier even mee helpen?')}`}>Send me a message (WhatsApp)</a>
          </li>
          <br/>
          <li>
            <a href="mailto:me@temme.nu">me@temme.nu</a>
          </li>
        </ul>
        <ul>
          <li>KVK: 66196922</li>
          <br/>
          <li>Kloosterweg 1</li>
          <li>6412CN Heerlen</li>
          <li>The Netherlands</li>
        </ul>
      </footer>
    </div>
  );
}

export default App;
